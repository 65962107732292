
import $ from 'jquery';
import { initProductSummary } from 'catalog/product-summary';

window.initProductSummary = initProductSummary;

function toggleMenu()
{
	$('body').toggleClass('show-nav');
}

function expandSubMenu(link)
{
	var parent = $(link).parent();
	var menu = parent.find('ul.flyout-menu:first');

	menu.stop(true,true);

	if (parent.hasClass('expanded'))
	{
		menu.slideUp();
		parent.removeClass('expanded');
	}
	else
	{
		menu.slideDown();
		parent.addClass('expanded');
	}
}

$(function(){
	var event = (document.documentElement && 'ontouchend' in document.documentElement) ? 'touchend' : 'click';
	$('#toggle-menu-btn').on(event, function(e){
		toggleMenu();
		e.preventDefault();
		return false;
	});

	$('#navigation .expand-arrow').on(event, function(){
		expandSubMenu(this);
		return false;
	});

});