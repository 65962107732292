
import $ from 'jquery';

class ImageMagnifier
{
	constructor(el)
	{
		var previewSrc, fullSrc;

		previewSrc = $(el).attr('data-preview-src');
		fullSrc = $(el).attr('data-full-src');

		// save container element
		this.el = $('<div class="image-magnifier-main"/>');
		this.el.data('data-magnifier', this);

		// setup DOM structure
		this.el.empty();
		this.el.append('<div class="image-preview-canvas"/>');
		this.el.append('<div class="image-magnified"/>');

		this.canvas = this.el.find('.image-preview-canvas');
		this.magnified = this.el.find('.image-magnified');

		$(el).replaceWith(this.el);

		// initialize image
		this.setImage(previewSrc, fullSrc);

		// add handlers
		this.el.on('mousemove', this.handleMouseMove.bind(this));
		this.el.on('touchstart', this.startTouchDrag.bind(this));
		this.el.on('touchend', this.stopTouchDrag.bind(this));
		this.el.on('touchmove', this.handleTouchDrag.bind(this));

		this.dragId = 0;
	}

	setImage(preview, full)
	{
		let previewImage = new Image();
		let fullImage = new Image();

		this.previewLoaded = false;
		this.fullLoaded = false;

		previewImage.onload = () => {
			this.previewLoaded = true;
			this.canvas.empty();
			this.canvas.append(previewImage);
		}

		fullImage.onload = () => {
			this.fullLoaded = true;
			this.magnified.empty();
			this.magnified.append(fullImage);
		}

		previewImage.src = preview;
		fullImage.src = full;
	}

	setMagnifiedPosition(mx, my)
	{
		// normalize input coordinates to 0..1
		var scaleX = mx / this.canvas.width();
		var scaleY = my / this.canvas.height();
		
		// get size of magnified element
		var canW = this.canvas.width();
		var canH = this.canvas.height();
		var magW = this.magnified.width();
		var magH = this.magnified.height();

		// get offset in magnified image 
		var magX = magW * scaleX;
		var magY = magH * scaleY;

		if (magX >= magW - canW)
			magX = magW - canW;

		if (magY >= magH - canH)
			magY = magH - canH;

		// move magnified image by offset
		this.magnified.css({
			marginLeft: `-${magX}px`,
			marginTop: `-${magY}px`,
		});
	}

	handleMouseMove(event)
	{
		var rect = this.el.get(0).getBoundingClientRect();
		var x = event.clientX - rect.left;
		var y = event.clientY - rect.top;
		this.setMagnifiedPosition(x, y);
	}

	startTouchDrag(event)
	{
		console.log('start-o');
		console.log(this.dragId);

		if (this.dragId == 0)
		{
			let pos = this.getTouchPos(event.touches[0]);
			this.setMagnifiedPosition(pos.x, pos.y);

			this.dragId = event.touches[0].identifier;
			this.el.addClass('is-active');

			event.preventDefault();
		}
	}

	stopTouchDrag(event)
	{
		this.dragId = 0;
		this.el.removeClass('is-active');
	}

	getTouchPos(touch)
	{
		var rect, x, y;

		rect = this.el.get(0).getBoundingClientRect();
		x = touch.clientX - rect.left;
		y = touch.clientY - rect.top;

		return { x, y };
	}

	handleTouchDrag(event)
	{
		var touch, pos;

		if (this.dragId == 0)	
			return;

		for(var touch of event.touches)
		{
			if (touch.identifier != this.dragId)
				continue;

			event.preventDefault();

			pos = this.getTouchPos(touch);
			this.setMagnifiedPosition(pos.x, pos.y);
		}
	}

}

export function initImageMagnifier(expr)
{
	return new ImageMagnifier(expr);
}

//window.initImageMagnifier = initImageMagnifier;