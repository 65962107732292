

import Swal from 'sweetalert2';
//import '@sweetalert2/themes/material-ui/material-ui.min.css';

const swal = Swal;

export default {
	async show(text, title, type)
	{
		await swal.fire(title, text, type);
	},

	async confirm(text, title, type)
	{
		var result = await swal.fire({ text, title, icon: type || 'question', showCancelButton: true });
		return result.value ? true : false;
	}
}