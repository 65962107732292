
import _ from 'lodash/string';
import moment from 'moment';
import { sprintf } from 'sprintf-js';

export default {
	currency(value)
	{
		var formatter = new Intl.NumberFormat('en-US', { 
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2
		});
		var num = parseFloat(value) || 0;
		return formatter.format(num);
	},

	dateFormat(value, format)
	{
		if (typeof(value) == 'string')
			return moment(value).format(format);
		else if (typeof(value) == 'number')
			return moment.unix(value).format(format);
		else if (typeof(value) == 'object' && typeof(value.format) == 'function')
			return value.format(format);
		else
			return `!INV: ${value}`;
	},

	to12Hour(value)
	{
		return moment(value, 'HH:mm').format('h:mma');
	},

	numberFormat(value, decimals)
	{
		var dec = parseInt(decimals, 10) || 2;
		return sprintf(`%0.0${dec}f`, value);
		//return parseFloat(value).toFixed(decimals);
	},

	round(value)
	{
		return Math.round(value);
	},

	/*nthNumber(num)
	{
		return util.nthNumber(num);
	},*/

	capitalize(val)
	{
		return _.capitalize(val);
	},

	titleCase(val)
	{
		return _.startCase(val);
	},

	// expects val to be minutes, set mult to 60 if the input value is actually hours
	duration(val, mult=1)
	{
		var num = val * mult;
		var hours = Math.round(num / 60);
		var minutes = _.padStart(Math.round(num % 60), 2, '0');
		return `${hours}h ${minutes}m`;
	}

}