
import $ from 'jquery';

import { createApi } from 'admin/shared/api';

import formatters from 'common/formatters';
import alerts from 'common/alerts';

import { initImageMagnifier } from './magnifier';

const api = createApi('/api');

function getSizeOption(size, options)
{
	// initialize with highest option by default
	var result = options[options.length-1]

	// look for nearest highest option
	for(var value of options)
	{
		if (size <= value)
		{
			result = value;
			break;
		}
	}

	return result;
}

class ProductSummary
{
	constructor(el, prices)
	{
		var self = this;

		// get elements
		this.el = $(el);
		this.el.data('cb-ps-vm', this);
		this.priceDisplay = this.el.find('[data-price]');
		this.discPriceDisplay = this.el.find('[data-disc-price]');
		this.prices = prices;

		// get width/height steps
		this.widthOptions = Object.keys(prices);
		this.heightOptions = Object.keys(prices[this.widthOptions[0]]);

		// get width/height fields
		this.widthField = this.el.find('select[data-size=width]');
		this.widthFracField = this.el.find('select[data-size=width-frac]');
		this.heightField = this.el.find('select[data-size=height]');
		this.heightFracField = this.el.find('select[data-size=height-frac]');
		
		// simple state
		this.state = {
			width: parseFloat(this.widthField.val()),
			widthFrac: parseFloat(this.widthFracField.val()),
			height: parseFloat(this.heightField.val()),
			heightFrac: parseFloat(this.heightFracField.val()),
		};

		// update price when width or height change
		this.widthField.on('change', function(){
			self.updatePrice({ width: parseFloat($(this).val()) });
		});

		this.widthFracField.on('change', function(){
			self.updatePrice({ widthFrac: parseFloat($(this).val()) });
		});

		this.heightField.on('change', function(){
			self.updatePrice({ height: parseFloat($(this).val()) });
		});

		this.heightFracField.on('change', function(){
			self.updatePrice({ heightFrac: parseFloat($(this).val()) });
		});

		// force update
		this.updatePrice();
	}

	updatePrice(newState)
	{
		var price, width, height;

		// update state
		this.state = { ...this.state, ...newState };

		width = getSizeOption(this.state.width + this.state.widthFrac, this.widthOptions);
		height = getSizeOption(this.state.height + this.state.heightFrac, this.heightOptions);

		// make sure width is valid
		if (!this.prices[width])
		{
			alerts.show(`Unable to find price based on width: ${width}`, 'Error', 'error');
			return;			
		}

		// make sure height is valid
		if (!this.prices[width][height])
		{
			alerts.show(`Unable to find price based on height: ${height}`, 'Error', 'error');
			return;			
		}

		// get price to display
		price = this.prices[width][height];
		this.priceDisplay.text(formatters.currency(price.original));
		this.discPriceDisplay.text(formatters.currency(price.discounted));
	}
}

async function addSampleToCart(btn)
{
	var item = {
		item_type: 'sample',
		sample_option_id: $(btn).attr('data-option-id'),
		qty: 1,
	};

	$(btn).prop('disabled', true);

	/*$.ajax({
		url: '/api/cart',
		type: 'post',
		data: req,
		complete() { 
			$(btn).prop('disabled', false);
		},
		error() {
			alerts.show('An error occurred while adding to your cart', 'Error', 'error');
		},
		success() {
			alerts.show('Sample added to your cart', 'Success', 'success');
		}
	});*/

	try
	{
		await api.post('/cart', { item });
		await alerts.show('Sample added to your cart', 'Success', 'success');
	}
	finally
	{
		$(btn).prop('disabled', false);
	}
}

export function initProductSummary(el, prices)
{
	// setup product summary VM
	var summary = new ProductSummary(el, prices);

	// setup image magnifier
	var mag = initImageMagnifier('#product-image-viewer');

	// change out magnifier image
	$('#product-images .thumbnail-image').on('click', function(e){
		var full = $(this).attr('data-full-src');
		var preview = $(this).attr('data-preview-src');
		
		mag.setImage(preview, full);

		e.preventDefault();

		return false;
	});

	// bind handlers for sample buttons
	$('#product-sample-options .sample-item-add').on('click', function(e) {
		addSampleToCart(this);
	});
}